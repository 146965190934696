<template>
  <b-card>
    <ol
      class="c6 lst-kix_list_7-1 start"
      start="1"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.3rdcrjn"
          style="display:inline"
        >
          <span class="c1">Tambah Perintah (Staff SE) </span>
        </h2>
      </li>
    </ol>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 151.18px; height: 323.44px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image19.png')"
          style="width: 151.18px; height: 468.81px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Untuk mengakses menu tambah perintah, user harus memilih menu tambah perintah yang ada pada bilah sidebar yang berada pada sisi kiri layar, lalu user akan diarahkan ke form </span><span class="c8">Perintah </span><span class="c4">untuk mengisi semua informasi yang dibutuhkan untuk disposisi seperti yang ditampilkan pada gambar dibawah ini:</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 342.88px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image21.png')"
          style="width: 709.80px; height: 342.88px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c12 c19">
      <span class="c4">Lalu setelah semua informasi yang dibutuhkan untuk surat perintah sudah lengkap, maka scroll mouse dan arahkan ke arah kiri bawah pada form tambah perintah untuk menyimpan surat perintah.</span>
    </p>

    <b-img
      alt=""
      :src="require('@/assets/images/images-1/image23.png')"
      style="width: 820.64px; height: 345.14px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""
    />
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="2"
    >
      <li class="c14 li-bullet-2">
        <h2
          id="h.26in1rg"
          style="display:inline"
        >
          <span class="c1">Daftar Perintah (Pihak Terkait Perintah)</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <a id="t.06747337297ddfba45bba75ce6b22fcf66a295c5" /><a id="t.5" />
    <table class="c23">
      <tr class="c25">
        <td
          class="c42"
          colspan="1"
          rowspan="1"
        >
          <p class="c17">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 151.18px; height: 323.64px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image25.png')"
                style="width: 151.18px; height: 323.64px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
        <td
          class="c48"
          colspan="1"
          rowspan="1"
        >
          <p class="c41">
            <span>Pilih </span><span class="c8">Proses Perintah</span><span>&nbsp;atau </span><span class="c8">Perintah Selesai</span>
          </p>
        </td>
        <td
          class="c45"
          colspan="1"
          rowspan="1"
        >
          <p class="c17">
            <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 151.18px; height: 323.64px;">
              <b-img
                alt=""
                :src="require('@/assets/images/images-1/image26.png')"
                style="width: 151.18px; height: 323.64px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
                title=""
              />
            </span>
          </p>
        </td>
      </tr>
    </table>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c18 c40">
      <span class="c4">Untuk melihat daftar perintah yang telah dibuat sebelumnya, user bisa mengaksesnya dengan memilih menu </span><span class="c11 c8">proses perintah</span><span class="c4">&nbsp;atau </span><span class="c11 c8">perintah selesai</span><span class="c4">&nbsp;dimana user akan di arahkan kepada </span><span class="c11 c8">detail perintah</span><span class="c4">&nbsp;berdasarkan pada status perintah dimana status </span><span class="c11 c8">open</span><span class="c4">&nbsp;adalah </span><span class="c11 c8">proses perintah</span><span class="c4">&nbsp;dan status </span><span class="c11 c8">close</span><span class="c4">&nbsp;adalah </span><span class="c11 c8">perintah selesai</span><span class="c4">. setelah salah satu menu tersebut dipilih maka akan muncul tampilan seperti berikut dibawah:</span>
    </p>
    <p class="c2 c12">
      <span class="c4" />
    </p>
    <p class="c19">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 318.21px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image27.png')"
          style="width: 709.80px; height: 318.21px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="3"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.lnxbz9"
          style="display:inline"
        >
          <span class="c1">Detail Perintah (Pihak Terkait Perintah)</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c5">
      <span class="c22">Setelah kita melakukan tahapan diatas yaitu melihat </span><span class="c27 c8">daftar perintah</span><span class="c22">&nbsp;baik </span><span class="c27 c8">Proses Perintah </span><span class="c22">maupun </span><span class="c27 c8">Perintah</span><span class="c8 c49"><br></span><span class="c27 c8">Selesai </span><span class="c22">maka kita bisa memilih </span><span class="c8 c27">surat perintah</span><span class="c22">&nbsp;mana yang akan dilihat detail suratnya dengan meng-klik pada bagian</span><span class="c47"><br></span><span class="c22">kolom </span><span class="c27 c8">No. Disposisi </span><span class="c22">yaitu pada nomor </span><span class="c27 c8">No. Perintah</span><span class="c22">&nbsp;yang digaris bawahi yang ingin dipilih.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 268.55px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image28.png')"
          style="width: 709.80px; height: 268.55px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c5">
      <span class="c4">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setelah pada kotak merah tersebut di-klik, maka pengguna akan dirahkan ke halaman </span><span class="c11 c8">Form</span><span class="c4">&nbsp;</span><span class="c11 c8">Detail Perintah</span><span class="c4">, pada halaman tersebut kita bisa melihat detail dari perintah yang sudah ditambahkan sebelumnya.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 283.89px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image29.png')"
          style="width: 709.80px; height: 283.89px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 279.29px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image30.png')"
          style="width: 709.80px; height: 279.29px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c5">
      <span>Pada menu ini juga user juga bisa melihat file perintah yang sudah diupload sebelumnya, juga jika sebelumnya user merupakan pihat yang disebut atau terkait dengan surat perintah yaitu </span><span class="c8">PIC (Penanggung Jawab)</span><span>&nbsp;dan </span><span class="c8">responder (Divisi / Bagian /Unit Terkait ) </span><span class="c4">maka user tersebut bisa menambahkan komentar pada detail perintah seperti yang akan dijelaskan pada tahapan selanjutnya.</span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="4"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.35nkun2"
          style="display:inline"
        >
          <span class="c1">Edit Perintah (Staff SE)</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c5">
      <span>Jika diperlukan pengubahan data pada surat perintah, user bisa mengakses menu edit perintah melalui daftar perintah baik </span><span class="c8">Proses Perintah</span><span>&nbsp;atau </span><span class="c8">Perintah Selesai</span><span>, maupun melalui </span><span class="c8">Form Detail Perintah</span><span class="c4">, dengan langkah-langkah sebagai berikut.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-2"
      start="4"
    >
      <li class="c14 li-bullet-1">
        <h3
          id="h.1ksv4uv"
          style="display:inline"
        >
          <span class="c1">Akses Menu Edit Melalui Daftar Perintah (Staff SE).</span>
        </h3>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 140.54px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image31.png')"
          style="width: 709.80px; height: 140.54px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c19 c24">
      <span class="c4">Edit Melalui Daftar Perintah bisa dilakukan dengan langkah-langkah sebagai berikut:</span>
    </p>
    <p class="c19 c24">
      <span>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mengarahkan pointer mouse kearah kanan dan meng-klik symbol </span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 19.50px; height: 18.19px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image9.png')"
          style="width: 1563.93px; height: 649.66px; margin-left: -1481.21px; margin-top: -557.84px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c4">&nbsp;atau titik-titik vertical. </span>
    </p>
    <p class="c18 c24">
      <span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lalu mengklik dilanjutkan dengan meng-klik perintah </span><span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 1.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 63.65px; height: 25.05px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image9.png')"
          style="width: 856.00px; height: 355.13px; margin-left: -779.43px; margin-top: -277.54px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span><span class="c4">&nbsp;atau edit.</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-2"
      start="5"
    >
      <li class="c14 li-bullet-1">
        <h3
          id="h.44sinio"
          style="display:inline"
        >
          <span class="c1">Akses Menu Edit Melalui Halaman Detail Perintah (Staff SE).</span>
        </h3>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c18 c24">
      <span class="c4">Jika anda sedang berada pada halaman Detail Perintah dan anda login sebagai Staff SE, maka anda bisa mengakses fitur edit perintah dengan mengarahkan pointer mouse ke pojok kanan atas form perintah lalu meng-kliknya, sperti yang ditunjukkan oleh kotak warna merah pada gambar dibawah.</span>
    </p>
    <p class="c18 c13 c24">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 283.89px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image10.png')"
          style="width: 709.80px; height: 283.89px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <ol
      class="c6 lst-kix_list_7-2"
      start="6"
    >
      <li class="c14 li-bullet-1">
        <h3
          id="h.2jxsxqh"
          style="display:inline"
        >
          <span class="c1">Menyimpan Perintah Setelah Melakukan Edit Data (Staff SE).</span>
        </h3>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c18 c44">
      <span class="c4">Setelah user selesai melakukan semua pengeditan data perintah yang diperlukan, langkah selanjutnya adalah melakukan penyimpanan data yang telah di ubah tersebut, jika tidak dilakukan maka data yang telah diubah tidak akan tersimpan, berikut adalah cara menyimpan data yang telah di edit.</span>
    </p>
    <p class="c10 c13">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 312.72px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image11.png')"
          style="width: 709.80px; height: 312.72px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c18 c44">
      <span class="c4">Setelah data yang diperlukan telah di isi semua, langkah selanjutnya yaitu mengarahkan pointer mouse kea rah pojok kiri bawah dari seksi form perintah seperti yang diberi tanda kotak berwarna merah diatas.</span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="5"
    >
      <li class="c14 li-bullet-1">
        <h2
          id="h.z337ya"
          style="display:inline"
        >
          <span class="c1">Komentar Perintah (Pihak Terkait Perintah).</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c5">
      <span>Menu </span><span class="c8">Komentar Perintah</span><span>&nbsp;bisa diakses melalui halaman </span><span class="c8">Detail Perintah</span><span>, untuk bisa menambahkan komentar pada </span><span class="c8">Detail Perintah</span><span>, user harus memiliki akses sebagai</span><span class="c8">&nbsp;PIC (Penanggung Jawab)</span><span>&nbsp;atau sebagai </span><span class="c8">responder (Divisi / Bagian /Unit Terkait)</span><span class="c4">, berikut adalah contoh cara menambahkan komentar.</span>
    </p>
    <p class="c18 c13">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 440.00px; height: 184.87px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image12.png')"
          style="width: 740.39px; height: 184.87px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c7">
      <span class="c4" />
    </p>
    <p class="c15">
      <span class="c4">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Langkah pertama yaitu mengisi komentar pada kolom komentar yang telah disediakan seperti yang di tunjukkan pada kotak merah no.1 diatas.</span>
    </p>
    <p class="c15">
      <span class="c4">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setelah kolom komentar telah diisi, maka langkah selanjutnya yaitu mengklik perintah Tambah Komentar yang ditunjukkan pada kotak merah no.2 diatas.</span>
    </p>
    <p class="c18 c13">
      <span class="c4" />
    </p>
    <ol
      class="c6 lst-kix_list_7-1"
      start="6"
    >
      <li class="c14 li-bullet-2">
        <h2
          id="h.3j2qqm3"
          style="display:inline"
        >
          <span class="c1">Ubah Status Perintah (Staff SE).</span>
        </h2>
      </li>
    </ol>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c19 c12">
      <span>Untuk mengubah status perintah, kita bisa mengakses fitur ubah status tersebut dari halaman </span><span class="c8">Detail Perintah</span><span>&nbsp;atau </span><span class="c8">Edit Perintah</span><span>. Pada halaman tersebut kita bisa menemukan perintah tersebut dengan melakukan scroll ke paling bawah halaman dan berada pada sis kiri halaman tersebut, berikut contoh untuk mengubah status perintah dari </span><span class="c8">Open</span><span>&nbsp;menjadi </span><span class="c8">Close</span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 709.80px; height: 279.29px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image13.png')"
          style="width: 709.80px; height: 279.29px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c2">
      <span class="c4" />
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 99.58px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image14.png')"
          style="width: 453.54px; height: 99.58px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 101.06px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image15.png')"
          style="width: 453.54px; height: 101.06px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c21">
      <span style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px); width: 453.54px; height: 100.08px;">
        <b-img
          alt=""
          :src="require('@/assets/images/images-1/image16.png')"
          style="width: 453.54px; height: 100.08px; margin-left: -0.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
          title=""
        />
      </span>
    </p>
    <p class="c19 c32">
      <span class="c4">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Setelah menemukan seksi atau bilah Status Perintah, hal pertama yang dilakukan adalah mengarahkan poiner mouse ke form status yang ditunjukkan pada kotak no.1, lalu kilk.</span>
    </p>
    <p class="c19 c32">
      <span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Selanjutnya akan tampil status perintah yang bisa dipilih seperti yang ditunjukkan pada kotak no.2, maka kita pilih yang </span><span class="c11 c8">close.</span>
    </p>
    <p class="c19 c32">
      <span class="c4">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Langkah terakhir yaitu menekan tombol simpan yang ditunjukkan pada kotak no.3.</span>
    </p>
    <hr style="page-break-before:always;display:none;">
    <p class="c2">
      <span class="c4" />
    </p>
  </b-card>
</template>

<script>
import { BImg, BCard } from 'bootstrap-vue'

export default {
  components: {
    BImg,
    BCard,
  },
}
</script>
